<script setup>
import { computed, onMounted, ref } from 'vue';
import { humanReadableTimestamp } from '@/composables/datetime.js';
import { useIncidentStore } from '@/store/incident.js';
import { penStateColor } from '@/composables/incidents/incidentPenStateHelper'

const props = defineProps({
  incident: { type: Object, default: null, required: false },
});

const incidentStore = useIncidentStore();
const loading = ref(true);
const loaded = ref(false);
const penStateIncident = ref({});
const penState = ref(null);

const color = computed(() => penStateColor(penState.value));

onMounted(async () => {
  // If an incident is passed, load the pen state for this incident
  if (props.incident) {
    penState.value = await incidentStore.loadPenStateDuration(props.incident);
    loading.value = false;
    loaded.value = true;
    return;
  }

  // Otherwise, load the latest pen state
  penStateIncident.value = await incidentStore.loadLatestPenState();
  penState.value = await incidentStore.loadPenStateDuration(
    penStateIncident.value,
  );

  loading.value = false;
  loaded.value = !!penStateIncident.value;
});
</script>

<template>
  <v-layout-card
    v-if="loaded"
    :loading="loading"
  >
    <v-row
      class="float-end ma-0"
      :dense="true"
      :no-gutters="true"
    >
      <v-col cols="auto">
        <slot name="commentButton" />
      </v-col>
      <v-col cols="auto">
        <slot name="assignButton" />
      </v-col>
    </v-row>
    <v-card-title>{{ $t("incidents.barnState.title") }}</v-card-title>
    <v-card-text>
      <v-alert
        :color="color"
        type="info"
      >
        {{
          $t("incidents.barnState.statusTexts." + penState.state, {
            timestamp:
              penState.state === "penFull"
                ? humanReadableTimestamp(penState.firstEventTimestamp)
                : humanReadableTimestamp(penState.lastEventTimestamp),
            day: penState.duration,
          })
        }}
      </v-alert>
    </v-card-text>
    <v-card-actions class="justify-end">
      <slot
        name="genericActions"
        v-bind="{
          enableNewEventButton: true,
          newEventButtonText: $t('incidents.barnState.actions.new-event'),
        }"
      />
    </v-card-actions>
  </v-layout-card>
</template>
<style scoped></style>
