import { useTheme } from 'vuetify/lib/framework.mjs'

const isClosedPenState = penState => {
  return penState.state === 'penEmpty' || penState.state === 'closed'
}

const penStateColor = penState => {
  const theme = useTheme();
  if (penState.state === 'penFull') return 'green';
  if (penState.state === 'penEmpty') return theme.current.value.colors.primary;
  if (penState.state === 'closed') return 'grey';
  return 'red';
}

export {
  isClosedPenState,
  penStateColor,
}