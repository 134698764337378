
<script setup>
import { computed, ref, watch } from 'vue';
import { useTheme } from 'vuetify';
import ChartTimeSetupPicker from '@/components/charts/ChartTimeSetupPicker.vue';
import { useLogsStore } from '@/store/logs.js';
import { Chart } from 'highcharts-vue'
import Highcharts from 'highcharts';
import exportData from 'highcharts/modules/export-data';
import exportingInit from 'highcharts/modules/exporting';
import xrange from 'highcharts/modules/xrange';
import offlineExportingInit from 'highcharts/modules/offline-exporting';
import { getCurrentTimezone, humanReadableTimestamp } from '@/composables/datetime.js';
import { useRouter } from 'vue-router';
import { useInstanceStore } from '@/store/backendInstances.js';
const theme = useTheme()
xrange(Highcharts);
exportingInit(Highcharts);
offlineExportingInit(Highcharts);
exportData(Highcharts);

const props = defineProps({
  defaultCollapsed: { type: Boolean, default: false },
  height: { type: Number, default: 400 },
  chartType: { type: String, default: 'line' },
  options: {
    type: Object, default: () => {
    },
  },
  dataLoader: {
    type: Function, default: async function () {
    },
  },
  disableControlRow: { type: Boolean, default: false },
  hideTimeResolution: { type: Boolean, default: false },
  chainTimeSetup: { type: Boolean, default: false },
  xAxisType: { type: String, default: 'datetime' },
  title: { type: String, default: '' },
  subtitle: { type: String, default: '' },
  enableClickEvent: { type: Boolean, default: true },
})
const router = useRouter()

const canJumpToFrameSeek = computed(() => {
  if(!clickedDataPoint.value) return false
  return useInstanceStore().hasAnyPermission([
    'recordings.view.frame',
    'streaming.recording.view',
    'recordings.view',
  ])
})
function jumpToFrameSeek(){
  const query = { timestamp: clickedDataPoint.value.x }
  if(clickedDataPoint.value.series.options.dimensionKeys) {
    const index = clickedDataPoint.value.series.options.dimensionKeys.findIndex(key => key === 'camera')
    if(index !== -1) {
      query.cameraId =clickedDataPoint.value.series.options.datasetSelector[index]
    }
  }
  return router.push({ name: 'Frame Seek', query: query })
}
const canJumpToCanvas = computed(() => {
  if(!clickedDataPoint.value) return false
  return useInstanceStore().hasAnyPermission(['cameraCanvases.view', 'cameraCanvases.view.basic'])
})
function jumpToCanvas() {
  return router.push({ name: 'CameraCanvas', query: { timestamp: clickedDataPoint.value.x } })
}
const canJumpToRecordingStream = computed(() => {
  if(!clickedDataPoint.value?.series?.options?.dimensionKeys || !clickedDataPoint.value.series.options.dimensionKeys.includes('camera'))
    return false
  return useInstanceStore().hasAnyPermission(['streaming.recording.view'])
})
function jumpToRecordingStream() {
  const index = clickedDataPoint.value.series.options.dimensionKeys.findIndex(key => key === 'camera')
  const cameraId =clickedDataPoint.value.series.options.datasetSelector[index]
  return router.push({ name: 'RecordingStream', params: { selectorMode: 'camera', selectorId: cameraId, timestamp: clickedDataPoint.value.x } })
}

const expanded = ref(!props.defaultCollapsed)
const timeSetup = defineModel('timeSetup', { required: true, type: Object })

const allShown = ref(false)
const allHidden = ref(false)
const baseOptions = {
  credits: {
    text: 'vetvise.com',
    href: 'https://vetvise.com',
  },
  time: {
    useUTC: false,
    timezone: getCurrentTimezone(),
  },
  plotOptions: {
    series: {
      stickyTracking: false,
      groupPadding: 0,
      events: {
        hide: function () {
          allShown.value = chart.value?.chart.series.every(series => series.visible)
          allHidden.value = chart.value?.chart.series.every(series => !series.visible)
        },
        show: function () {
          allShown.value = chart.value?.chart.series.every(series => series.visible)
          allHidden.value = chart.value?.chart.series.every(series => !series.visible)

        },
      },
    },
  },
  exporting: {
    enabled: true,
    fallbackToExportServer: false,
    sourceWidth: 3000,
    sourceHeight: 500,
  },
  tooltip: {
    distance: 25,
    hideDelay: 100,
    pointFormatter: function(){
      return `<span style='color: ${this.color}'>●</span> ${this.series.name}: <b>${this.y.toPrecision(5)}</b><br>`
    },
  },
  yAxis: {
    backgroundColor: theme.current.value.colors.surface,
    labels: {
      style: {
        color: theme.current.value.colors.onSurface,
      },
    },
  },
  legend:{
    itemStyle: {
      color: theme.current.value.colors.onSurface,

    },

  },
  xAxis: {
    backgroundColor: theme.current.value.colors.surface,
    labels: {
      style: {
        color: theme.current.value.colors.onSurface,
      },
    },
  },
  chart: {
    style: {
      fontFamily: 'roboto',
    },
    zooming: {
      type: 'xy',
    },
    backgroundColor: theme.current.value.colors.surface,
  },
}

const chart = ref(null)
const showError = ref(false)

const loaded = ref(false)
const initialLoaded = ref(false)
const chartData = ref(null)
const clickedDataPoint = ref(null)
const showDataPointDialog = ref(false)
const chartOptions = computed(() => {
  const options = { ...baseOptions, ...props.options, ...chartData.value }
  if (!options.chart) options.chart = {}
  if (props.height) options.chart.height = props.height
  options.chart.type = props.chartType
  if (!options.xAxis) options.xAxis = {}
  options.xAxis.type = props.xAxisType
  options.title = { text: props.title, style: { color: theme.current.value.colors.onSurface } }
  options.subtitle = { text: props.subtitle, style: { color: theme.current.value.colors.onSurface } }
  if(props.enableClickEvent) {
    if(!options.plotOptions) options.plotOptions = {}
    if(!options.plotOptions.series) options.plotOptions.series = {}
    if(!options.plotOptions.series.point) options.plotOptions.series.point = {}
    options.plotOptions.series.point.events = {
      click: function() {
        openDataPointClickDialog(this)
      },
    }
  }
  return options
})
function openDataPointClickDialog(dataPoint) {
  clickedDataPoint.value = dataPoint
  showDataPointDialog.value = true
}
watch(() => timeSetup.value, (value, oldValue) => {
  if (!initialLoaded.value || value.start !== oldValue.start || value.end !== oldValue.end || value.timeResolution !== oldValue.timeResolution) {
    if (expanded.value) {
      loadData()
    } else loaded.value = false
  }
}, { deep: true, immediate: true })

const legendHeight = computed(() => {
  if(!chart.value?.chart?.legend) return 0
  const height = chart.value.chart.legend.legendHeight || 0
  const fullHeight = chart.value.chart.legend.fullHeight || 0
  return Math.max(0, height, fullHeight)
})
watch(legendHeight, () => {
  if(!legendHeight.value || !chart.value?.chart) return
  chart.value.chart.setSize(null, props.height + legendHeight.value)
  chart.value.chart.redraw()

}, { immediate: true })

function hideAllSeries() {
  chart.value.chart.series.forEach(series => {
    series.setVisible(false, false)
  })
  chart.value.chart.redraw()
}

function showAllSeries() {
  chart.value.chart.series.forEach(series => {
    series.setVisible(true, false)
  })
  chart.value.chart.redraw()
}

async function expandChart() {
  expanded.value = true
  if (!initialLoaded.value || !loaded.value) {
    await loadData()
  }
}

async function loadData() {
  showError.value = false;
  loaded.value = false;
  try {
    chartData.value = await props.dataLoader(timeSetup.value)
    initialLoaded.value = true;
  } catch (e) {
    showError.value = true;
    useLogsStore().addLogEntry({ message: 'Could not load Data for chart', error: e, tag: 'chart', level: 'ERROR' })
  }
  loaded.value = true;
}

function injectChartData(data) {
  chartData.value = data
  loaded.value = true
  initialLoaded.value = true;
}

defineExpose({ loadData, injectChartData })
</script>

<template>
  <v-container
    fluid
    class="pa-0"
  >
    <v-row
      v-if="!expanded"
      justify="center"
    >
      <v-col>
        <v-layout-card>
          <v-card-title>{{ props.title }}</v-card-title>
          <v-card-subtitle>{{ props.subtitle }}</v-card-subtitle>
          <v-card-text>{{ $t("charts.click_to_show") }}</v-card-text>
          <v-card-actions class="justify-center">
            <v-btn
              variant="flat"
              color="primary"
              block
              @click="expandChart()"
            >
              {{ $t("charts.expand") }}
            </v-btn>
          </v-card-actions>
        </v-layout-card>
      </v-col>
    </v-row>


    <v-fade-transition>
      <v-layout-card v-if="expanded">
        <v-row>
          <v-col
            v-if="!disableControlRow"
            :cols="12"
          >
            <v-row
              class="pa-2 justify-end"
              :no-gutters="true"
            >
              <v-col
                v-if="!chainTimeSetup"
                cols="auto"
              >
                <ChartTimeSetupPicker
                  v-model="timeSetup"
                  :hide-time-resolution="hideTimeResolution"
                />
              </v-col>
              <v-col cols="auto">
                <v-btn
                  class="ma-2 rounded-pill"
                  prepend-icon="mdi-reload"
                  @click="loadData"
                >
                  {{ $t("charts.reload") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-card-title v-if="!loaded">
          {{ props.title }}
        </v-card-title>
        <v-card-subtitle v-if="!loaded && props.subtitle">
          {{ props.subtitle }}
        </v-card-subtitle>
        <v-card-text v-if="loaded">
          <v-alert
            v-if="showError"
            class="my-4"
            type="error"
            prominent
            variant="elevated"
          >
            {{ $t("charts.could_not_load") }}
          </v-alert>
          <Chart
            v-else
            ref="chart"
            :options="chartOptions"
          />
          <v-btn
            v-if="!showError"
            variant="outlined"
            size="small"
            class="ma-2"
            :disabled="allHidden"
            @click="hideAllSeries"
          >
            {{ $t("charts.hide_all") }}
          </v-btn>
          <v-btn
            v-if="!showError"
            variant="outlined"
            size="small"
            class="ma-2"
            :disabled="allShown"
            @click="showAllSeries"
          >
            {{ $t("charts.show_all") }}
          </v-btn>
        </v-card-text>

        <v-card-text
          v-if="!loaded"
          class="justify-center"
        >
          <v-row>
            <v-col :cols="12">
              {{ $t("charts.loading_text") }}
            </v-col>
            <v-col
              :cols="12"
              class="justify-center"
            >
              <v-layout class="justify-center">
                <v-progress-circular
                  :indeterminate="true"
                  class="justify-center"
                />
              </v-layout>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            variant="outlined"
            size="small"
            @click="expanded = false"
          >
            {{ $t("charts.collapse") }}
          </v-btn>
        </v-card-actions>
      </v-layout-card>
    </v-fade-transition>
  </v-container>
  <v-dialog
    v-model="showDataPointDialog"
    width="auto"
    max-width="600"
    min-width="300"
  >
    <v-layout-card>
      <v-card-title>{{ humanReadableTimestamp(clickedDataPoint.x) }}</v-card-title>
      <v-card-subtitle>{{ clickedDataPoint.series.name }}</v-card-subtitle>
      <v-card-text>
        <v-btn
          v-if="canJumpToCanvas"
          class="mt-4"
          block
          variant="outlined"
          color="primary"
          prepend-icon="mdi-artboard"
          @click="jumpToCanvas"
        >
          {{ $t('charts.go_to_canvas') }}
        </v-btn>
        <v-btn
          v-if="canJumpToFrameSeek"
          class="mt-4"
          block
          variant="outlined"
          color="primary"
          prepend-icon="mdi-image-frame"
          @click="jumpToFrameSeek"
        >
          {{ $t('charts.go_to_frame_seek') }}
        </v-btn>
        <v-btn
          v-if="canJumpToRecordingStream"
          class="mt-4"
          block
          variant="outlined"
          color="primary"
          prepend-icon="mdi-video-outline"
          @click="jumpToRecordingStream"
        >
          {{ $t('charts.go_to_recording_stream') }}
        </v-btn>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="error"
          variant="outlined"
          @click="showDataPointDialog = false"
        >
          {{ $t('general_interface.buttons.close') }}
        </v-btn>
      </v-card-actions>
    </v-layout-card>
  </v-dialog>
</template>
