import Ajv from 'ajv'
import addFormats from 'ajv-formats'
const ajv = new Ajv()
addFormats(ajv)
import forge from 'node-forge'
import i18n from '../plugins/i18n'
import { DateTime } from 'luxon';
const isUri = ajv.compile( { type: 'string', format: 'uri' })
const isIPV4 = ajv.compile({ type: 'string', format: 'ipv4' })
const isHostname = ajv.compile({ type: 'string', format: 'hostname' })
const isEmail = ajv.compile({ type: 'string', format: 'email' })
const isUriReference = ajv.compile({ type: 'string', format: 'uri-reference' })

const { t } = i18n.global

const inputRequiredRule = value => {if(value) return true; return t('input_rules.required')}

const minLengthRule = minLength => value => {
  if (value && value.length < minLength) return t('input_rules.min_length_not_met', minLength)
  return true;
}

const maxLengthRule = maxLength => value => {
  if (value && value.length > maxLength) return t('input_rules.max_length_exceeded', maxLength)
  return true;
}
const minimumNumberRule = minimumNumber => value => {
  if(value !== undefined && value !== null && value < minimumNumber) 
    return t('input_rules.minimum_number', minimumNumber)
  return true;
}

const maximumNumberRule = maximumNumber => value => {
  if(value !== undefined && value !== null && value > maximumNumber) 
    return t('input_rules.maximum_number', maximumNumber)
  return true;
}

const exclusiveMinimumNumberRule = minimumNumber => value => {
  if(value !== undefined && value !== null && value <= minimumNumber) 
    return t('input_rules.exclusive_minimum_number', minimumNumber)
  return true;
}

const exclusiveMaximumNumberRule = maximumNumber => value => {
  if(value !== undefined && value !== null && value >= maximumNumber) 
    return t('input_rules.exclusive_maximum_number', maximumNumber)
  return true;
}

const isNumberOrNumberStringRule = value => {
  if(value !== undefined && value !== null && !Number.isFinite(value) && !Number.isFinite(Number(value))) 
    return t('input_rules.is_number_or_number_string')
  return true;

}

const isIntegerRule = () => value => {
  if(value !== undefined && value !== null && !Number.isInteger(value)) return t('input_rules.is_integer')
  return true;
}

const isUriRule = () => value => {
  if(value !== undefined && value !== null && !isUri(value)) return t('input_rules.is_uri')
  return true;
}

const isIPV4Rule = () => value => {
  if(value !== undefined && value !== null && !isIPV4(value)) return t('input_rules.is_ipv4')
  return true;
}

const isHostnameRule = () => value => {
  if(value !== undefined && value !== null && !isHostname(value)) return t('input_rules.is_hostname')
  return true;
}

const isEmailRule = value => {
  if(value !== undefined && value !== null && !isEmail(value)) return t('input_rules.is_email')
  return true;
}

const isUriReferenceRule = () => value => {
  if(value !== undefined && value !== null && !isUriReference(value)) return t('input_rules.is_uri_reference')
  return true;
}

const isPEMKeyRule = value => {
  if(value === undefined || value === null) return true;
  try {
    const pubkey = forge.pki.publicKeyFromPem(value.split('\\r\\n').join(''))
    if (pubkey.n.bitLength() !== 4096) return t('input_rules.is_pem_key')
    return true
  } catch {/** **/}
  return t('input_rules.is_pem_key')
}

const atLeastOneUppercaseLetterRule = value => {
  if(value === undefined || value === null) return true;
  if(!/[A-Z]/.test(value)) return t('input_rules.at_least_one_uppercase_letter')
  return true
}

const atLeastOneLowercaseLetterRule = value => {
  if(value === undefined || value === null) return true;
  if(!/[a-z]/.test(value)) return t('input_rules.at_least_one_lowercase_letter')
  return true
}

const atLeastOneNumberRule = value => {
  if(value === undefined || value === null) return true;
  if(!/[0-9]/.test(value)) return t('input_rules.at_least_one_number')
  return true
}

const atLeastOneSpecialCharacterRule = value => {
  if(value === undefined || value === null) return true;
  if(!new RegExp(/[@$!%*?&€.,\-:;_()[\]{}<>/=#+'|~]/).test(value)) 
    return t('input_rules.at_least_one_special_character')
  return true
}

const validPasswordRule = value => {
  if(value === undefined || value === null) return true;
  if (
    !new RegExp(
      // eslint-disable-next-line max-len
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&€.,\-:;_()[\]{}<>/=#+'|~])[A-Za-z\d@$!%*?&€.,\-:;_()[\]{}<>/=#+'|~]{8,70}$/,
    ).test(value)
  )
    return t('input_rules.password_invalid')
  return true;
}

const validUsernameRule = value => {
  if(value === undefined || value === null) return true;
  if(!new RegExp(/^[a-zA-Z0-9._-]{5,20}$/).test(value)) return t('input_rules.username_invalid')
  return true;
}

const passwordMatchRule = password => value => {
  if(value !== password) return t('input_rules.password_match')
  return true
}

const dateTimeInPast = value => {
  if(value === undefined || value === null) return true;
  if(DateTime.now() <= value) return t('input_rules.date_time_in_past')
  return true;
}

const eachFileNotLargerThan = maxSize => value => {
  if(value === undefined || value === null) return true;
  for (const file of value) {
    if(file.size > maxSize) return t('input_rules.file_too_large', maxSize);
  }
  return true;
}

const eachFileMatchesExtension = allowedExtensions => value => {
  if(value === undefined || value === null) return true;
  for (const file of value) {
    if(!allowedExtensions.includes(`.${file.name.split('.').pop()}`)) 
      return t('input_rules.file_extension_not_allowed', allowedExtensions);
  }
  return true;
}

const isHeightXWidthString = value => {
  if(value === undefined || value === null) return true;
  if(!new RegExp(/^\d+\s*x\s*\d+$/).test(value)) return t('input_rules.height_x_width_invalid')
  return true;
}

const onlyConsistsOfNumbersLowercaseAndUnderscore = value => {
  if(value === undefined || value === null) return true;
  if(!new RegExp(/^[a-z0-9_]+$/).test(value)) return t('input_rules.only_lowercase_and_underscore')
  return true;
}

export {
  isHeightXWidthString,
  inputRequiredRule,
  minLengthRule,
  maxLengthRule,
  minimumNumberRule,
  maximumNumberRule,
  exclusiveMinimumNumberRule,
  exclusiveMaximumNumberRule,
  isIntegerRule,
  isUriRule,
  isIPV4Rule,
  isHostnameRule,
  isEmailRule,
  isUriReferenceRule,
  isNumberOrNumberStringRule,
  isPEMKeyRule,
  atLeastOneUppercaseLetterRule,
  atLeastOneLowercaseLetterRule,
  atLeastOneNumberRule,
  atLeastOneSpecialCharacterRule,
  validPasswordRule,
  validUsernameRule,
  passwordMatchRule,
  dateTimeInPast,
  eachFileNotLargerThan,
  eachFileMatchesExtension,
  onlyConsistsOfNumbersLowercaseAndUnderscore,
}
